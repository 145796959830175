export enum RoutePaths {
    Dashboard = '/',
    AccountSetting = '/settings',
    MyPlugins = '/my-plugins',
    PaymentAlcCheckout = '/payment/product/checkout',
    PaymentAlcConfirmation = '/payment/product/confirmation',
    ChangeEmail = '/settings/update-email',
    DeleteAccount = '/settings/delete-account',
    PluginAuth = '/plugin-auth',
    PaymentSubscriptionCheckout = '/payment/subscription/checkout',
    PaymentSubscriptionCheckoutError = '/payment/subscription/checkout/error',
}

export enum LandrWebAppRoutePaths {
    PlansSettings = 'account/plans',
}

export const RouteNames = {
    [RoutePaths.Dashboard]: 'Dashboard',
    [RoutePaths.AccountSetting]: 'Account',
    [RoutePaths.MyPlugins]: 'My Plugins',
    [RoutePaths.PaymentAlcCheckout]: 'Checkout',
    [RoutePaths.PaymentAlcConfirmation]: 'Checkout',
    [RoutePaths.ChangeEmail]: 'ChangeEmail',
    [RoutePaths.PaymentSubscriptionCheckout]: 'SubscriptionCheckout',
    [RoutePaths.PaymentSubscriptionCheckoutError]: 'SubscriptionCheckoutError',
};
