import { gql } from '@apollo/client';

const SubscriptionStatusFragment = gql`
    fragment SubscriptionStatusFragment on HasActiveSASubscription {
        id
        discountPercentage
        isEligibleForLoyaltyDiscount
        hasSynchroartsArtist
        hasSynchroartsProfessional
        hasSynchroartsTrialSubscription
        hasSynchroartsMonthlySubscription
        trialEndDate
        isPaused
        commitment
    }
`;

export const GetActiveSASubscriptionsQuery = gql`
    query getActiveSASubscriptions {
        activeSASubscriptions {
            ...SubscriptionStatusFragment
        }
    }
    ${SubscriptionStatusFragment}
`;

export const ActivateTrialSubscriptionMutation = gql`
    mutation activateTrialSubscription {
        activateTrialSubscription {
            subscription {
                ...SubscriptionStatusFragment
            }
        }
    }
    ${SubscriptionStatusFragment}
`;
