export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: string;
    Void: any;
};

export type Query = {
    __typename?: 'Query';
    pluginCatalog: Array<CatalogItem>;
    subscriptionInclusions: SubscriptionInclusionsOutput;
    getInstallerDownloadUrl: Scalars['String'];
    pluginItem: CatalogItem;
    iLokAccount?: Maybe<ILokAccount>;
    validateILokAccount?: Maybe<ValidateILokAccount>;
    /** @deprecated Use getLicencesActivations instead */
    licencesActivations?: Maybe<Array<Maybe<LicenceActivation>>>;
    getLicencesActivations?: Maybe<LicenceActivationOutput>;
    userProfile?: Maybe<User>;
    userAgreements: UserAgreements;
    userChangeEmailRequest: GethasEmailChangeRequest;
    userCommunicationPreferences: UserCommunicationPreferences;
    validateChangeEmailToken: Scalars['Boolean'];
    pricingCatalog: PricingCatalog;
    checkoutSessionStatus: CheckoutSessionStatus;
    activeSASubscriptions?: Maybe<HasActiveSaSubscription>;
};

export type QueryGetInstallerDownloadUrlArgs = {
    productId: Scalars['ID'];
    platform: PluginPlatform;
};

export type QueryPluginItemArgs = {
    slug: Scalars['String'];
};

export type QueryValidateILokAccountArgs = {
    iLokId: Scalars['String'];
};

export type QueryLicencesActivationsArgs = {
    licenceId: Scalars['String'];
};

export type QueryGetLicencesActivationsArgs = {
    licenceId: Scalars['String'];
};

export type QueryValidateChangeEmailTokenArgs = {
    token: Scalars['String'];
};

export type QueryCheckoutSessionStatusArgs = {
    sessionId: Scalars['ID'];
};

export type Mutation = {
    __typename?: 'Mutation';
    pluginStartTrial: PluginStartTrialOutput;
    generatePluginLicense: PluginGenerateLicenseOutput;
    redeemCode: RedeemCodeResponse;
    linkILokAccount: PluginLinkILokAccountOutput;
    syncILokLicenses: PluginSyncILokLicensesOutput;
    /** @deprecated Use cancelLicenceActivation instead */
    deleteLicenceActivation?: Maybe<Scalars['Void']>;
    cancelLicenceActivation?: Maybe<LicenceActivationOutput>;
    acceptUserAgreements: UserAgreements;
    updateUserCommunicationPreferences: UserCommunicationPreferences;
    changePassword: Scalars['Boolean'];
    updateProfile: User;
    requestEmailChange: UserChangeEmailRequest;
    changeEmail: User;
    /** Flow to delete the account without support ticket */
    requestUserAccountDeletion: RequestUserAccountDeletionOutput;
    deleteUserAccount?: Maybe<Scalars['Void']>;
    twoFactorStart: TwoFactorStartOutput;
    twoFactorSend?: Maybe<Scalars['Void']>;
    prepareAlcCheckout: CheckoutAlcSession;
    /** @deprecated Use generateStoreBillingPortalLink */
    generateBillingPortalLink: BillingPortalLink;
    generateStoreBillingPortalLink: BillingPortalLink;
    activateTrialSubscription: ActivateTrialSubscriptionOutput;
};

export type MutationPluginStartTrialArgs = {
    productId: Scalars['ID'];
};

export type MutationGeneratePluginLicenseArgs = {
    productId: Scalars['ID'];
    purchaseId: Scalars['ID'];
};

export type MutationRedeemCodeArgs = {
    code: Scalars['String'];
};

export type MutationLinkILokAccountArgs = {
    iLokId: Scalars['String'];
};

export type MutationDeleteLicenceActivationArgs = {
    licenceId: Scalars['String'];
    activationId: Scalars['String'];
};

export type MutationCancelLicenceActivationArgs = {
    licenceId: Scalars['String'];
    activationId: Scalars['String'];
};

export type MutationUpdateUserCommunicationPreferencesArgs = {
    input: UpdateUserCommunicationPreferencesInput;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationUpdateProfileArgs = {
    input: UpdateProfileInput;
};

export type MutationChangeEmailArgs = {
    changeEmailInput: ChangeEmailInput;
};

export type MutationDeleteUserAccountArgs = {
    token: Scalars['String'];
};

export type MutationTwoFactorSendArgs = {
    input: TwoFactorSendInput;
};

export type MutationPrepareAlcCheckoutArgs = {
    input: CheckoutAlcInput;
};

export type MutationGenerateBillingPortalLinkArgs = {
    returnUrl: Scalars['String'];
};

export type MutationGenerateStoreBillingPortalLinkArgs = {
    input: GenerateStoreBillingPortalLinkInput;
};

export enum PluginPlatform {
    MacOs = 'MacOs',
    Windows = 'Windows',
}

export enum PluginStatus {
    Activated = 'Activated',
    Available = 'Available',
    NotAvailable = 'NotAvailable',
    Paused = 'Paused',
}

export type PluginUpsellInformations = {
    __typename?: 'PluginUpsellInformations';
    alcCode: Scalars['String'];
    planCode?: Maybe<Scalars['String']>;
};

export type PluginInstallers = {
    __typename?: 'PluginInstallers';
    id: Scalars['ID'];
    latestInstallerVersion: Scalars['String'];
};

export enum PluginCodeType {
    License = 'License',
}

export enum PluginLabelCode {
    License = 'License',
    LicenseTrial = 'License_Trial',
    LicenseILok = 'License_iLok',
}

export enum UnavailabilityReason {
    Paused = 'Paused',
    TrialEnded = 'TrialEnded',
}

export type PluginCode = {
    __typename?: 'PluginCode';
    id: Scalars['ID'];
    code?: Maybe<Scalars['String']>;
    bundleName?: Maybe<Scalars['String']>;
    planCode?: Maybe<Scalars['String']>;
    labelOverride?: Maybe<PluginLabelCode>;
    isTrialing: Scalars['Boolean'];
};

export type PluginLicenseCodes = {
    __typename?: 'PluginLicenseCodes';
    id: Scalars['ID'];
    type: PluginCodeType;
    labelCode: PluginLabelCode;
    codes: Array<PluginCode>;
};

export enum DiscountType {
    Upgrade = 'Upgrade',
    Crossgrade = 'Crossgrade',
    Sale = 'Sale',
}

export type ApplicableDiscount = {
    __typename?: 'ApplicableDiscount';
    type: DiscountType;
    percent: Scalars['Float'];
    couponCode?: Maybe<Scalars['String']>;
};

export type CatalogItem = {
    __typename?: 'CatalogItem';
    id: Scalars['ID'];
    slug: Scalars['String'];
    applicableDiscount?: Maybe<ApplicableDiscount>;
    blurb?: Maybe<Scalars['String']>;
    canStartTrial: Scalars['Boolean'];
    codes?: Maybe<PluginLicenseCodes>;
    imageUrl: Scalars['String'];
    installer?: Maybe<PluginInstallers>;
    isLocked: Scalars['Boolean'];
    name: Scalars['String'];
    status: PluginStatus;
    trialDurationDays: Scalars['Int'];
    unavailabilityReason?: Maybe<UnavailabilityReason>;
    upsellInformations?: Maybe<PluginUpsellInformations>;
    userManualUrl?: Maybe<Scalars['String']>;
    videoUrl?: Maybe<Scalars['String']>;
};

export type PluginStartTrialOutput = {
    __typename?: 'PluginStartTrialOutput';
    catalogItem: CatalogItem;
};

export type PluginGenerateLicenseOutput = {
    __typename?: 'PluginGenerateLicenseOutput';
    pluginCode: PluginCode;
};

export type RedeemCodeResponse = {
    __typename?: 'RedeemCodeResponse';
    id: Scalars['ID'];
    catalogItem?: Maybe<CatalogItem>;
};

export type ILokAccount = {
    __typename?: 'ILokAccount';
    id: Scalars['ID'];
    iLokId?: Maybe<Scalars['String']>;
};

export type ValidateILokAccount = {
    __typename?: 'ValidateILokAccount';
    isValid?: Maybe<Scalars['Boolean']>;
};

export type PluginLinkILokAccountOutput = {
    __typename?: 'PluginLinkILokAccountOutput';
    iLokAccount: ILokAccount;
    iLokLicenses: Array<CatalogItem>;
    activeSASubscriptions: HasActiveSaSubscription;
};

export type PluginSyncILokLicensesOutput = {
    __typename?: 'PluginSyncILokLicensesOutput';
    iLokLicenses: Array<CatalogItem>;
};

export type LicenceActivation = {
    __typename?: 'LicenceActivation';
    activationId?: Maybe<Scalars['String']>;
    machineName?: Maybe<Scalars['String']>;
    os?: Maybe<Scalars['String']>;
    activatedAt?: Maybe<Scalars['String']>;
};

export type LicenceActivationOutput = {
    __typename?: 'LicenceActivationOutput';
    id: Scalars['ID'];
    licenceActivations: Array<LicenceActivation>;
};

export type ExternalIncludedPlugin = {
    __typename?: 'ExternalIncludedPlugin';
    id: Scalars['ID'];
    slug: Scalars['String'];
    imageUrl: Scalars['String'];
    name: Scalars['String'];
};

export type ExtraInclusions = {
    __typename?: 'ExtraInclusions';
    id: Scalars['ID'];
    numberOfExtraPlugins: Scalars['Int'];
};

export type SubscriptionInclusionsOutput = {
    __typename?: 'SubscriptionInclusionsOutput';
    id: Scalars['ID'];
    externalIncludedPlugins: Array<ExternalIncludedPlugin>;
    extraInclusions?: Maybe<ExtraInclusions>;
    numberOfAvailablePluginsWithYearlySub: Scalars['Int'];
};

export enum HomePageEnum {
    Mastering = 'Mastering',
    Distribution = 'Distribution',
    Samples = 'Samples',
    Network = 'Network',
    Dashboard = 'Dashboard',
    Sessions = 'Sessions',
    Plugins = 'Plugins',
    Chromatic = 'Chromatic',
    Projects = 'Projects',
}

export enum OccupationEnum {
    Engineer = 'Engineer',
    Educator = 'Educator',
    Artist = 'Artist',
    SoundDesign = 'Sound_design',
    Composer = 'Composer',
    Producer = 'Producer',
    LabelOwner = 'Label_owner',
    AR = 'A_R',
    Songwriter = 'Songwriter',
    Instrumentalist = 'Instrumentalist',
    Journalist = 'Journalist',
    ArtistManager = 'Artist_manager',
    Agent = 'Agent',
    Bassist = 'Bassist',
    Beatmaker = 'Beatmaker',
    Blogger = 'Blogger',
    Copywriter = 'Copywriter',
    Designer = 'Designer',
    Dj = 'DJ',
    Drummer = 'Drummer',
    EventProgrammer = 'Event_programmer',
    GearManufacturer = 'Gear_manufacturer',
    Guitarist = 'Guitarist',
    GraphicDesigner = 'Graphic_designer',
    Influencer = 'Influencer',
    Keyboardist = 'Keyboardist',
    Lyricist = 'Lyricist',
    MediaFigure = 'Media_figure',
    Moderator = 'Moderator',
    MultiInstrumentalist = 'Multi_instrumentalist',
    MusicDirector = 'Music_director',
    Musician = 'Musician',
    Pianist = 'Pianist',
    Photographer = 'Photographer',
    PodcastHost = 'Podcast_host',
    Promoter = 'Promoter',
    Publisher = 'Publisher',
    Publicist = 'Publicist',
    Singer = 'Singer',
    StudioOwner = 'Studio_owner',
    RadioHost = 'Radio_host',
    Videographer = 'Videographer',
    Vlogger = 'Vlogger',
    YouTuber = 'YouTuber',
    WebDesigner = 'Web_designer',
}

export enum MusicGenre {
    Acoustic = 'Acoustic',
    Afrobeat = 'Afrobeat',
    Americana = 'Americana',
    Blues = 'Blues',
    Chill = 'Chill',
    Choral = 'Choral',
    Classical = 'Classical',
    Country = 'Country',
    Dubstep = 'Dubstep',
    Edm = 'Edm',
    ElectricPop = 'Electric_pop',
    Electronic = 'Electronic',
    Folk = 'Folk',
    Funk = 'Funk',
    Gospel = 'Gospel',
    HeavyMetal = 'Heavy_metal',
    HeavyRock = 'Heavy_rock',
    HipHop = 'Hip_hop',
    House = 'House',
    IndiePop = 'Indie_pop',
    Jazz = 'Jazz',
    Latin = 'Latin',
    Pop = 'Pop',
    PopRock = 'Pop_rock',
    Reggae = 'Reggae',
    Reggaeton = 'Reggaeton',
    Rnb = 'Rnb',
    Rock = 'Rock',
    SingerSongwriter = 'Singer_songwriter',
    SoftPop = 'Soft_pop',
    Soul = 'Soul',
    Soundtrack = 'Soundtrack',
    Techno = 'Techno',
    Trance = 'Trance',
    Trap = 'Trap',
    Tropical = 'Tropical',
    World = 'World',
}

export type User = {
    __typename?: 'User';
    userId: Scalars['String'];
    email?: Maybe<Scalars['String']>;
    firstname?: Maybe<Scalars['String']>;
    lastname?: Maybe<Scalars['String']>;
};

export enum LegalAgreementKindEnum {
    TermsOfService = 'TermsOfService',
    PrivacyPolicy = 'PrivacyPolicy',
}

export type Agreement = {
    __typename?: 'Agreement';
    id: Scalars['ID'];
    requireUpdate?: Maybe<Scalars['Boolean']>;
    kind: LegalAgreementKindEnum;
    version?: Maybe<Scalars['String']>;
};

export type UserAgreements = {
    __typename?: 'UserAgreements';
    id: Scalars['ID'];
    agreements: Array<Agreement>;
};

export type Agreements = {
    __typename?: 'Agreements';
    agreements?: Maybe<Array<Maybe<Agreement>>>;
};

export type UserCommunicationPreferences = {
    __typename?: 'UserCommunicationPreferences';
    isSubscribedToPersonalizedTips: Scalars['Boolean'];
    isSubscribedToLandrUpdates: Scalars['Boolean'];
    isSubscribedToLandrNewsLetter: Scalars['Boolean'];
    isSubscribedToLandrSamplesNewsLetter: Scalars['Boolean'];
    isSubscribedToLandrExclusivePromotions: Scalars['Boolean'];
};

export type UserChangeEmailRequest = {
    __typename?: 'UserChangeEmailRequest';
    id: Scalars['ID'];
    hasEmailChangeRequest: Scalars['Boolean'];
};

export type GethasEmailChangeRequest = {
    __typename?: 'GethasEmailChangeRequest';
    hasEmailChangeRequest: Scalars['Boolean'];
};

export type UpdateUserCommunicationPreferencesInput = {
    isSubscribedToPersonalizedTips: Scalars['Boolean'];
    isSubscribedToLandrUpdates: Scalars['Boolean'];
    isSubscribedToLandrNewsLetter: Scalars['Boolean'];
    isSubscribedToLandrSamplesNewsLetter: Scalars['Boolean'];
    isSubscribedToLandrExclusivePromotions: Scalars['Boolean'];
};

export type UpdateProfileInput = {
    firstname: Scalars['String'];
    lastname?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordInput = {
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
    twoFactor?: InputMaybe<TwoFactorInput>;
};

export type ChangeEmailInput = {
    token: Scalars['String'];
    currentEmail: Scalars['String'];
    newEmail: Scalars['String'];
};

export type RequestUserAccountDeletionOutput = {
    __typename?: 'RequestUserAccountDeletionOutput';
    isEligible: Scalars['Boolean'];
};

export enum TwoFactorMethodType {
    Email = 'Email',
    Authenticator = 'Authenticator',
    Unknown = 'Unknown',
}

export type TwoFactorMethod = {
    __typename?: 'TwoFactorMethod';
    id: Scalars['ID'];
    type: TwoFactorMethodType;
};

export type TwoFactorStartOutput = {
    __typename?: 'TwoFactorStartOutput';
    twoFactorId: Scalars['ID'];
    methods: Array<TwoFactorMethod>;
};

export type TwoFactorSendInput = {
    methodId: Scalars['String'];
    twoFactorId: Scalars['String'];
};

export type TwoFactorInput = {
    code: Scalars['String'];
    twoFactorId: Scalars['String'];
};

export enum ServiceOfferKind {
    Samples = 'Samples',
    Mastering = 'Mastering',
    Distribution = 'Distribution',
    Sessions = 'Sessions',
    Bundles = 'Bundles',
    Chromatic = 'Chromatic',
    Plugins = 'Plugins',
    PremiumCourses = 'PremiumCourses',
    SynchroArts = 'SynchroArts',
    SynchroArtsSubscription = 'SynchroArtsSubscription',
}

export enum CheckoutPurchaseAnalyticsType {
    Subscription = 'Subscription',
    Alc = 'Alc',
    Trial = 'Trial',
}

export enum AnalyticsPurchaseLicenseType {
    NewLicense = 'NewLicense',
    UpgradeLicense = 'UpgradeLicense',
    CrossgradeLicense = 'CrossgradeLicense',
}

export enum CheckoutPurchaseAnalyticsCommitment {
    Yearly = 'Yearly',
    Monthly = 'Monthly',
    AnnualBilledMonthly = 'AnnualBilledMonthly',
    OneTime = 'OneTime',
}

export enum SubscriptionCommitment {
    Yearly = 'Yearly',
    Monthly = 'Monthly',
}

export enum CheckoutRefund {
    None = 'None',
    Prorated = 'Prorated',
    Full = 'Full',
    Partial = 'Partial',
}

export type CheckoutRewardsInfo = {
    referenceId: Scalars['String'];
    couponCode: Scalars['String'];
};

export type CheckoutAlcInput = {
    productCode: Scalars['String'];
    quantity?: InputMaybe<Scalars['Int']>;
    couponCode?: InputMaybe<Scalars['String']>;
    adjustableQuantity?: InputMaybe<Scalars['Boolean']>;
    successUrl: Scalars['String'];
    cancelUrl: Scalars['String'];
    rewardsInfo?: InputMaybe<CheckoutRewardsInfo>;
};

export type HasActiveSaSubscription = {
    __typename?: 'HasActiveSASubscription';
    id: Scalars['ID'];
    discountPercentage?: Maybe<Scalars['Float']>;
    isEligibleForLoyaltyDiscount: Scalars['Boolean'];
    hasSynchroartsArtist: Scalars['Boolean'];
    hasSynchroartsProfessional: Scalars['Boolean'];
    hasSynchroartsTrialSubscription: Scalars['Boolean'];
    hasSynchroartsMonthlySubscription: Scalars['Boolean'];
    trialEndDate?: Maybe<Scalars['String']>;
    isPaused: Scalars['Boolean'];
    commitment?: Maybe<SubscriptionCommitment>;
};

export type CheckoutAlcSession = {
    __typename?: 'CheckoutAlcSession';
    checkoutUrl: Scalars['String'];
};

export type CheckoutPurchaseProductAnalytics = {
    __typename?: 'CheckoutPurchaseProductAnalytics';
    type: CheckoutPurchaseAnalyticsType;
    licenceType?: Maybe<AnalyticsPurchaseLicenseType>;
    serviceOffer: ServiceOfferKind;
    billingCommitment: CheckoutPurchaseAnalyticsCommitment;
    productCode: Scalars['String'];
    quantity: Scalars['Int'];
    unitPriceCAD: Scalars['Float'];
    discountCAD: Scalars['Float'];
    coupon?: Maybe<Scalars['String']>;
};

export type CheckoutPurchaseAnalytics = {
    __typename?: 'CheckoutPurchaseAnalytics';
    orderId: Scalars['String'];
    serviceOffer: ServiceOfferKind;
    discountCAD: Scalars['Float'];
    revenueCAD: Scalars['Float'];
    products: Array<CheckoutPurchaseProductAnalytics>;
    coupon?: Maybe<Scalars['String']>;
};

export type CheckoutSessionStatus = {
    __typename?: 'CheckoutSessionStatus';
    id: Scalars['ID'];
    isSessionCompleted: Scalars['Boolean'];
    purchaseAnalytics?: Maybe<CheckoutPurchaseAnalytics>;
};

export type BillingPortalLink = {
    __typename?: 'BillingPortalLink';
    portalUrl: Scalars['String'];
};

export type PricingCatalogItem = {
    __typename?: 'PricingCatalogItem';
    code: Scalars['String'];
    productId: Scalars['String'];
    price: Scalars['Float'];
};

export type Currency = {
    __typename?: 'Currency';
    currencyCode: Scalars['String'];
    currencySymbol: Scalars['String'];
};

export type PricingCatalog = {
    __typename?: 'PricingCatalog';
    currency: Currency;
    plugins: Array<PricingCatalogItem>;
};

export enum Store {
    Landr = 'LANDR',
    SynchroArts = 'SynchroArts',
}

export type GenerateStoreBillingPortalLinkInput = {
    returnUrl: Scalars['String'];
    store?: InputMaybe<Store>;
};

export type ActivateTrialSubscriptionOutput = {
    __typename?: 'ActivateTrialSubscriptionOutput';
    subscription: HasActiveSaSubscription;
};

export type CheckoutSessionStatusFragmentFragment = {
    __typename?: 'CheckoutSessionStatus';
    id: string;
    isSessionCompleted: boolean;
    purchaseAnalytics?:
        | {
              __typename?: 'CheckoutPurchaseAnalytics';
              orderId: string;
              discountCAD: number;
              revenueCAD: number;
              coupon?: string | undefined;
              serviceOffer: ServiceOfferKind;
              products: Array<{
                  __typename?: 'CheckoutPurchaseProductAnalytics';
                  type: CheckoutPurchaseAnalyticsType;
                  licenceType?: AnalyticsPurchaseLicenseType | undefined;
                  serviceOffer: ServiceOfferKind;
                  billingCommitment: CheckoutPurchaseAnalyticsCommitment;
                  productCode: string;
                  quantity: number;
                  unitPriceCAD: number;
                  discountCAD: number;
                  coupon?: string | undefined;
              }>;
          }
        | undefined;
};

export type GetCheckoutSessionStatusQueryVariables = Exact<{
    sessionId: Scalars['ID'];
}>;

export type GetCheckoutSessionStatusQuery = {
    __typename?: 'Query';
    checkoutSessionStatus: {
        __typename?: 'CheckoutSessionStatus';
        id: string;
        isSessionCompleted: boolean;
        purchaseAnalytics?:
            | {
                  __typename?: 'CheckoutPurchaseAnalytics';
                  orderId: string;
                  discountCAD: number;
                  revenueCAD: number;
                  coupon?: string | undefined;
                  serviceOffer: ServiceOfferKind;
                  products: Array<{
                      __typename?: 'CheckoutPurchaseProductAnalytics';
                      type: CheckoutPurchaseAnalyticsType;
                      licenceType?: AnalyticsPurchaseLicenseType | undefined;
                      serviceOffer: ServiceOfferKind;
                      billingCommitment: CheckoutPurchaseAnalyticsCommitment;
                      productCode: string;
                      quantity: number;
                      unitPriceCAD: number;
                      discountCAD: number;
                      coupon?: string | undefined;
                  }>;
              }
            | undefined;
    };
};

export type GenerateStoreBillingPortalLinkMutationVariables = Exact<{
    input: GenerateStoreBillingPortalLinkInput;
}>;

export type GenerateStoreBillingPortalLinkMutation = {
    __typename?: 'Mutation';
    generateStoreBillingPortalLink: {
        __typename?: 'BillingPortalLink';
        portalUrl: string;
    };
};

export type TwoFactorStartMutationVariables = Exact<{ [key: string]: never }>;

export type TwoFactorStartMutation = {
    __typename?: 'Mutation';
    twoFactorStart: {
        __typename?: 'TwoFactorStartOutput';
        twoFactorId: string;
        methods: Array<{
            __typename?: 'TwoFactorMethod';
            id: string;
            type: TwoFactorMethodType;
        }>;
    };
};

export type TwoFactorSendMutationVariables = Exact<{
    input: TwoFactorSendInput;
}>;

export type TwoFactorSendMutation = {
    __typename?: 'Mutation';
    twoFactorSend?: any | undefined;
};

export type ItemDetailsFragmentFragment = {
    __typename?: 'CatalogItem';
    id: string;
    slug: string;
    blurb?: string | undefined;
    imageUrl: string;
    videoUrl?: string | undefined;
    name: string;
    status: PluginStatus;
    isLocked: boolean;
    canStartTrial: boolean;
    trialDurationDays: number;
    unavailabilityReason?: UnavailabilityReason | undefined;
    userManualUrl?: string | undefined;
    upsellInformations?:
        | {
              __typename?: 'PluginUpsellInformations';
              alcCode: string;
              planCode?: string | undefined;
          }
        | undefined;
    installer?:
        | {
              __typename?: 'PluginInstallers';
              id: string;
              latestInstallerVersion: string;
          }
        | undefined;
    codes?:
        | {
              __typename?: 'PluginLicenseCodes';
              id: string;
              type: PluginCodeType;
              labelCode: PluginLabelCode;
              codes: Array<{
                  __typename?: 'PluginCode';
                  id: string;
                  code?: string | undefined;
                  labelOverride?: PluginLabelCode | undefined;
                  bundleName?: string | undefined;
                  planCode?: string | undefined;
                  isTrialing: boolean;
              }>;
          }
        | undefined;
    applicableDiscount?:
        | {
              __typename?: 'ApplicableDiscount';
              type: DiscountType;
              percent: number;
          }
        | undefined;
};

export type PluginCatalogQueryVariables = Exact<{ [key: string]: never }>;

export type PluginCatalogQuery = {
    __typename?: 'Query';
    iLokAccount?:
        | {
              __typename?: 'ILokAccount';
              id: string;
              iLokId?: string | undefined;
          }
        | undefined;
    pluginCatalog: Array<{
        __typename?: 'CatalogItem';
        id: string;
        slug: string;
        blurb?: string | undefined;
        imageUrl: string;
        videoUrl?: string | undefined;
        name: string;
        status: PluginStatus;
        isLocked: boolean;
        canStartTrial: boolean;
        trialDurationDays: number;
        unavailabilityReason?: UnavailabilityReason | undefined;
        userManualUrl?: string | undefined;
        upsellInformations?:
            | {
                  __typename?: 'PluginUpsellInformations';
                  alcCode: string;
                  planCode?: string | undefined;
              }
            | undefined;
        installer?:
            | {
                  __typename?: 'PluginInstallers';
                  id: string;
                  latestInstallerVersion: string;
              }
            | undefined;
        codes?:
            | {
                  __typename?: 'PluginLicenseCodes';
                  id: string;
                  type: PluginCodeType;
                  labelCode: PluginLabelCode;
                  codes: Array<{
                      __typename?: 'PluginCode';
                      id: string;
                      code?: string | undefined;
                      labelOverride?: PluginLabelCode | undefined;
                      bundleName?: string | undefined;
                      planCode?: string | undefined;
                      isTrialing: boolean;
                  }>;
              }
            | undefined;
        applicableDiscount?:
            | {
                  __typename?: 'ApplicableDiscount';
                  type: DiscountType;
                  percent: number;
              }
            | undefined;
    }>;
    subscriptionInclusions: {
        __typename?: 'SubscriptionInclusionsOutput';
        id: string;
        numberOfAvailablePluginsWithYearlySub: number;
        externalIncludedPlugins: Array<{
            __typename?: 'ExternalIncludedPlugin';
            id: string;
            slug: string;
            imageUrl: string;
            name: string;
        }>;
        extraInclusions?:
            | {
                  __typename?: 'ExtraInclusions';
                  id: string;
                  numberOfExtraPlugins: number;
              }
            | undefined;
    };
};

export type PluginItemQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type PluginItemQuery = {
    __typename?: 'Query';
    pluginItem: {
        __typename?: 'CatalogItem';
        id: string;
        slug: string;
        blurb?: string | undefined;
        imageUrl: string;
        videoUrl?: string | undefined;
        name: string;
        status: PluginStatus;
        isLocked: boolean;
        canStartTrial: boolean;
        trialDurationDays: number;
        unavailabilityReason?: UnavailabilityReason | undefined;
        userManualUrl?: string | undefined;
        upsellInformations?:
            | {
                  __typename?: 'PluginUpsellInformations';
                  alcCode: string;
                  planCode?: string | undefined;
              }
            | undefined;
        installer?:
            | {
                  __typename?: 'PluginInstallers';
                  id: string;
                  latestInstallerVersion: string;
              }
            | undefined;
        codes?:
            | {
                  __typename?: 'PluginLicenseCodes';
                  id: string;
                  type: PluginCodeType;
                  labelCode: PluginLabelCode;
                  codes: Array<{
                      __typename?: 'PluginCode';
                      id: string;
                      code?: string | undefined;
                      labelOverride?: PluginLabelCode | undefined;
                      bundleName?: string | undefined;
                      planCode?: string | undefined;
                      isTrialing: boolean;
                  }>;
              }
            | undefined;
        applicableDiscount?:
            | {
                  __typename?: 'ApplicableDiscount';
                  type: DiscountType;
                  percent: number;
              }
            | undefined;
    };
};

export type StartTrialMutationVariables = Exact<{
    productId: Scalars['ID'];
}>;

export type StartTrialMutation = {
    __typename?: 'Mutation';
    pluginStartTrial: {
        __typename?: 'PluginStartTrialOutput';
        catalogItem: {
            __typename?: 'CatalogItem';
            id: string;
            slug: string;
            blurb?: string | undefined;
            imageUrl: string;
            videoUrl?: string | undefined;
            name: string;
            status: PluginStatus;
            isLocked: boolean;
            canStartTrial: boolean;
            trialDurationDays: number;
            unavailabilityReason?: UnavailabilityReason | undefined;
            userManualUrl?: string | undefined;
            applicableDiscount?:
                | {
                      __typename?: 'ApplicableDiscount';
                      type: DiscountType;
                      percent: number;
                  }
                | undefined;
            upsellInformations?:
                | {
                      __typename?: 'PluginUpsellInformations';
                      alcCode: string;
                      planCode?: string | undefined;
                  }
                | undefined;
            installer?:
                | {
                      __typename?: 'PluginInstallers';
                      id: string;
                      latestInstallerVersion: string;
                  }
                | undefined;
            codes?:
                | {
                      __typename?: 'PluginLicenseCodes';
                      id: string;
                      type: PluginCodeType;
                      labelCode: PluginLabelCode;
                      codes: Array<{
                          __typename?: 'PluginCode';
                          id: string;
                          code?: string | undefined;
                          labelOverride?: PluginLabelCode | undefined;
                          bundleName?: string | undefined;
                          planCode?: string | undefined;
                          isTrialing: boolean;
                      }>;
                  }
                | undefined;
        };
    };
};

export type LinkILokAccountMutationVariables = Exact<{
    iLokId: Scalars['String'];
}>;

export type LinkILokAccountMutation = {
    __typename?: 'Mutation';
    linkILokAccount: {
        __typename?: 'PluginLinkILokAccountOutput';
        iLokAccount: {
            __typename?: 'ILokAccount';
            id: string;
            iLokId?: string | undefined;
        };
        iLokLicenses: Array<{
            __typename?: 'CatalogItem';
            id: string;
            slug: string;
            blurb?: string | undefined;
            imageUrl: string;
            videoUrl?: string | undefined;
            name: string;
            status: PluginStatus;
            isLocked: boolean;
            canStartTrial: boolean;
            trialDurationDays: number;
            unavailabilityReason?: UnavailabilityReason | undefined;
            userManualUrl?: string | undefined;
            applicableDiscount?:
                | {
                      __typename?: 'ApplicableDiscount';
                      percent: number;
                      type: DiscountType;
                  }
                | undefined;
            upsellInformations?:
                | {
                      __typename?: 'PluginUpsellInformations';
                      alcCode: string;
                      planCode?: string | undefined;
                  }
                | undefined;
            installer?:
                | {
                      __typename?: 'PluginInstallers';
                      id: string;
                      latestInstallerVersion: string;
                  }
                | undefined;
            codes?:
                | {
                      __typename?: 'PluginLicenseCodes';
                      id: string;
                      type: PluginCodeType;
                      labelCode: PluginLabelCode;
                      codes: Array<{
                          __typename?: 'PluginCode';
                          id: string;
                          code?: string | undefined;
                          labelOverride?: PluginLabelCode | undefined;
                          bundleName?: string | undefined;
                          planCode?: string | undefined;
                          isTrialing: boolean;
                      }>;
                  }
                | undefined;
        }>;
        activeSASubscriptions: {
            __typename?: 'HasActiveSASubscription';
            id: string;
            isEligibleForLoyaltyDiscount: boolean;
            discountPercentage?: number | undefined;
        };
    };
};

export type ILokAccountQueryVariables = Exact<{ [key: string]: never }>;

export type ILokAccountQuery = {
    __typename?: 'Query';
    iLokAccount?:
        | {
              __typename?: 'ILokAccount';
              id: string;
              iLokId?: string | undefined;
          }
        | undefined;
};

export type ValidateILokAccountQueryVariables = Exact<{
    iLokId: Scalars['String'];
}>;

export type ValidateILokAccountQuery = {
    __typename?: 'Query';
    validateILokAccount?:
        | { __typename?: 'ValidateILokAccount'; isValid?: boolean | undefined }
        | undefined;
};

export type SyncILokLicensesMutationVariables = Exact<{ [key: string]: never }>;

export type SyncILokLicensesMutation = {
    __typename?: 'Mutation';
    syncILokLicenses: {
        __typename?: 'PluginSyncILokLicensesOutput';
        iLokLicenses: Array<{
            __typename?: 'CatalogItem';
            id: string;
            slug: string;
            blurb?: string | undefined;
            imageUrl: string;
            videoUrl?: string | undefined;
            name: string;
            status: PluginStatus;
            isLocked: boolean;
            canStartTrial: boolean;
            trialDurationDays: number;
            unavailabilityReason?: UnavailabilityReason | undefined;
            userManualUrl?: string | undefined;
            applicableDiscount?:
                | {
                      __typename?: 'ApplicableDiscount';
                      percent: number;
                      type: DiscountType;
                  }
                | undefined;
            upsellInformations?:
                | {
                      __typename?: 'PluginUpsellInformations';
                      alcCode: string;
                      planCode?: string | undefined;
                  }
                | undefined;
            installer?:
                | {
                      __typename?: 'PluginInstallers';
                      id: string;
                      latestInstallerVersion: string;
                  }
                | undefined;
            codes?:
                | {
                      __typename?: 'PluginLicenseCodes';
                      id: string;
                      type: PluginCodeType;
                      labelCode: PluginLabelCode;
                      codes: Array<{
                          __typename?: 'PluginCode';
                          id: string;
                          code?: string | undefined;
                          labelOverride?: PluginLabelCode | undefined;
                          bundleName?: string | undefined;
                          planCode?: string | undefined;
                          isTrialing: boolean;
                      }>;
                  }
                | undefined;
        }>;
    };
};

export type GetLicencesActivationsQueryVariables = Exact<{
    licenceId: Scalars['String'];
}>;

export type GetLicencesActivationsQuery = {
    __typename?: 'Query';
    getLicencesActivations?:
        | {
              __typename?: 'LicenceActivationOutput';
              id: string;
              licenceActivations: Array<{
                  __typename?: 'LicenceActivation';
                  activationId?: string | undefined;
                  machineName?: string | undefined;
                  os?: string | undefined;
                  activatedAt?: string | undefined;
              }>;
          }
        | undefined;
};

export type CancelLicenceActivationMutationVariables = Exact<{
    licenceId: Scalars['String'];
    activationId: Scalars['String'];
}>;

export type CancelLicenceActivationMutation = {
    __typename?: 'Mutation';
    cancelLicenceActivation?:
        | {
              __typename?: 'LicenceActivationOutput';
              id: string;
              licenceActivations: Array<{
                  __typename?: 'LicenceActivation';
                  activationId?: string | undefined;
                  machineName?: string | undefined;
                  os?: string | undefined;
                  activatedAt?: string | undefined;
              }>;
          }
        | undefined;
};

export type PricingCatalogQueryVariables = Exact<{ [key: string]: never }>;

export type PricingCatalogQuery = {
    __typename?: 'Query';
    pricingCatalog: {
        __typename?: 'PricingCatalog';
        currency: {
            __typename?: 'Currency';
            currencyCode: string;
            currencySymbol: string;
        };
        plugins: Array<{
            __typename?: 'PricingCatalogItem';
            code: string;
            productId: string;
            price: number;
        }>;
    };
};

export type SubscriptionStatusFragmentFragment = {
    __typename?: 'HasActiveSASubscription';
    id: string;
    discountPercentage?: number | undefined;
    isEligibleForLoyaltyDiscount: boolean;
    hasSynchroartsArtist: boolean;
    hasSynchroartsProfessional: boolean;
    hasSynchroartsTrialSubscription: boolean;
    hasSynchroartsMonthlySubscription: boolean;
    trialEndDate?: string | undefined;
    isPaused: boolean;
    commitment?: SubscriptionCommitment | undefined;
};

export type GetActiveSaSubscriptionsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetActiveSaSubscriptionsQuery = {
    __typename?: 'Query';
    activeSASubscriptions?:
        | {
              __typename?: 'HasActiveSASubscription';
              id: string;
              discountPercentage?: number | undefined;
              isEligibleForLoyaltyDiscount: boolean;
              hasSynchroartsArtist: boolean;
              hasSynchroartsProfessional: boolean;
              hasSynchroartsTrialSubscription: boolean;
              hasSynchroartsMonthlySubscription: boolean;
              trialEndDate?: string | undefined;
              isPaused: boolean;
              commitment?: SubscriptionCommitment | undefined;
          }
        | undefined;
};

export type ActivateTrialSubscriptionMutationVariables = Exact<{
    [key: string]: never;
}>;

export type ActivateTrialSubscriptionMutation = {
    __typename?: 'Mutation';
    activateTrialSubscription: {
        __typename?: 'ActivateTrialSubscriptionOutput';
        subscription: {
            __typename?: 'HasActiveSASubscription';
            id: string;
            discountPercentage?: number | undefined;
            isEligibleForLoyaltyDiscount: boolean;
            hasSynchroartsArtist: boolean;
            hasSynchroartsProfessional: boolean;
            hasSynchroartsTrialSubscription: boolean;
            hasSynchroartsMonthlySubscription: boolean;
            trialEndDate?: string | undefined;
            isPaused: boolean;
            commitment?: SubscriptionCommitment | undefined;
        };
    };
};

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type UserProfileQuery = {
    __typename?: 'Query';
    userProfile?:
        | {
              __typename?: 'User';
              userId: string;
              email?: string | undefined;
              firstname?: string | undefined;
              lastname?: string | undefined;
          }
        | undefined;
    userChangeEmailRequest: {
        __typename?: 'GethasEmailChangeRequest';
        hasEmailChangeRequest: boolean;
    };
};

export type UpdateProfileMutationVariables = Exact<{
    input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
    __typename?: 'Mutation';
    updateProfile: {
        __typename?: 'User';
        userId: string;
        firstname?: string | undefined;
        lastname?: string | undefined;
    };
};

export type UserAgreementsQueryVariables = Exact<{ [key: string]: never }>;

export type UserAgreementsQuery = {
    __typename?: 'Query';
    userAgreements: {
        __typename?: 'UserAgreements';
        id: string;
        agreements: Array<{
            __typename?: 'Agreement';
            id: string;
            requireUpdate?: boolean | undefined;
            kind: LegalAgreementKindEnum;
            version?: string | undefined;
        }>;
    };
};

export type AcceptUserAgreementsMutationVariables = Exact<{
    [key: string]: never;
}>;

export type AcceptUserAgreementsMutation = {
    __typename?: 'Mutation';
    acceptUserAgreements: {
        __typename?: 'UserAgreements';
        id: string;
        agreements: Array<{
            __typename?: 'Agreement';
            id: string;
            requireUpdate?: boolean | undefined;
            kind: LegalAgreementKindEnum;
            version?: string | undefined;
        }>;
    };
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
    __typename?: 'Mutation';
    changePassword: boolean;
};

export type ChangeEmailInitialDataQueryVariables = Exact<{
    token: Scalars['String'];
}>;

export type ChangeEmailInitialDataQuery = {
    __typename?: 'Query';
    validateChangeEmailToken: boolean;
    userProfile?:
        | { __typename?: 'User'; userId: string; email?: string | undefined }
        | undefined;
};

export type UserCommunicationPreferencesFragmentFragment = {
    __typename?: 'UserCommunicationPreferences';
    isSubscribedToPersonalizedTips: boolean;
    isSubscribedToLandrUpdates: boolean;
    isSubscribedToLandrNewsLetter: boolean;
    isSubscribedToLandrSamplesNewsLetter: boolean;
    isSubscribedToLandrExclusivePromotions: boolean;
};

export type UserCommunicationPreferencesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type UserCommunicationPreferencesQuery = {
    __typename?: 'Query';
    userCommunicationPreferences: {
        __typename?: 'UserCommunicationPreferences';
        isSubscribedToPersonalizedTips: boolean;
        isSubscribedToLandrUpdates: boolean;
        isSubscribedToLandrNewsLetter: boolean;
        isSubscribedToLandrSamplesNewsLetter: boolean;
        isSubscribedToLandrExclusivePromotions: boolean;
    };
};

export type UpdateUserCommunicationPreferencesMutationVariables = Exact<{
    input: UpdateUserCommunicationPreferencesInput;
}>;

export type UpdateUserCommunicationPreferencesMutation = {
    __typename?: 'Mutation';
    updateUserCommunicationPreferences: {
        __typename?: 'UserCommunicationPreferences';
        isSubscribedToPersonalizedTips: boolean;
        isSubscribedToLandrUpdates: boolean;
        isSubscribedToLandrNewsLetter: boolean;
        isSubscribedToLandrSamplesNewsLetter: boolean;
        isSubscribedToLandrExclusivePromotions: boolean;
    };
};

export type RequestEmailChangeMutationVariables = Exact<{
    [key: string]: never;
}>;

export type RequestEmailChangeMutation = {
    __typename?: 'Mutation';
    requestEmailChange: {
        __typename?: 'UserChangeEmailRequest';
        id: string;
        hasEmailChangeRequest: boolean;
    };
};

export type ChangeEmailMutationVariables = Exact<{
    changeEmailInput: ChangeEmailInput;
}>;

export type ChangeEmailMutation = {
    __typename?: 'Mutation';
    changeEmail: {
        __typename?: 'User';
        userId: string;
        email?: string | undefined;
    };
};

export type RequestAccountDeletionMutationVariables = Exact<{
    [key: string]: never;
}>;

export type RequestAccountDeletionMutation = {
    __typename?: 'Mutation';
    requestUserAccountDeletion: {
        __typename?: 'RequestUserAccountDeletionOutput';
        isEligible: boolean;
    };
};

export type DeleteAccountMutationVariables = Exact<{
    token: Scalars['String'];
}>;

export type DeleteAccountMutation = {
    __typename?: 'Mutation';
    deleteUserAccount?: any | undefined;
};

export type GenerateBillingPortalLinkMutationVariables = Exact<{
    returnUrl: Scalars['String'];
}>;

export type GenerateBillingPortalLinkMutation = {
    __typename?: 'Mutation';
    generateBillingPortalLink: {
        __typename?: 'BillingPortalLink';
        portalUrl: string;
    };
};

export type GeneratePluginLicenseMutationVariables = Exact<{
    productId: Scalars['ID'];
    purchaseId: Scalars['ID'];
}>;

export type GeneratePluginLicenseMutation = {
    __typename?: 'Mutation';
    generatePluginLicense: {
        __typename?: 'PluginGenerateLicenseOutput';
        pluginCode: {
            __typename?: 'PluginCode';
            id: string;
            code?: string | undefined;
        };
    };
};

export type RedeemCodeMutationVariables = Exact<{
    code: Scalars['String'];
}>;

export type RedeemCodeMutation = {
    __typename?: 'Mutation';
    redeemCode: {
        __typename?: 'RedeemCodeResponse';
        catalogItem?:
            | {
                  __typename?: 'CatalogItem';
                  id: string;
                  slug: string;
                  imageUrl: string;
                  name: string;
                  status: PluginStatus;
                  isLocked: boolean;
                  canStartTrial: boolean;
                  trialDurationDays: number;
                  unavailabilityReason?: UnavailabilityReason | undefined;
                  upsellInformations?:
                      | {
                            __typename?: 'PluginUpsellInformations';
                            alcCode: string;
                            planCode?: string | undefined;
                        }
                      | undefined;
                  installer?:
                      | {
                            __typename?: 'PluginInstallers';
                            id: string;
                            latestInstallerVersion: string;
                        }
                      | undefined;
                  codes?:
                      | {
                            __typename?: 'PluginLicenseCodes';
                            id: string;
                            type: PluginCodeType;
                            labelCode: PluginLabelCode;
                            codes: Array<{
                                __typename?: 'PluginCode';
                                code?: string | undefined;
                                labelOverride?: PluginLabelCode | undefined;
                            }>;
                        }
                      | undefined;
              }
            | undefined;
    };
};

export type GetInstallerDownloadUrlQueryVariables = Exact<{
    productId: Scalars['ID'];
    platform: PluginPlatform;
}>;

export type GetInstallerDownloadUrlQuery = {
    __typename?: 'Query';
    getInstallerDownloadUrl: string;
};

export type PrepareAlcCheckoutMutationVariables = Exact<{
    input: CheckoutAlcInput;
}>;

export type PrepareAlcCheckoutMutation = {
    __typename?: 'Mutation';
    prepareAlcCheckout: {
        __typename?: 'CheckoutAlcSession';
        checkoutUrl: string;
    };
};
